import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Dollar from "./base/Dollar";
import { handleFieldChange } from "../../actions/formActions";

const DollarField = ({ name, onChange, onBlur, defValue, ...allOtherProps }) => {
    const dispatch = useDispatch();
    const value = useSelector(state => state.form.data[name]);

    React.useEffect(() => {
        if ((typeof value === "undefined" || value === null) && defValue) {
            dispatch(handleFieldChange({ name, value: defValue }));
        }
    }, [name, value, defValue, dispatch]);

    const handleChange = e => {
        const maxVal = 999999999999999.99;
        const val = e.target.value;
        const valid = Math.abs(val) <= maxVal;
        if (!valid) {
            dispatch(handleFieldChange({ name, value: maxVal }));
        }
        else {
            dispatch(handleFieldChange({ name, value: val }));
        }
        if (typeof onChange === "function") onChange(e);
    };

    const handleBlur = () => {
        if (typeof onBlur === "function") onBlur({ target: { name, value } });
    };

    return <Dollar {...allOtherProps} value={value} name={name} onChange={handleChange} onBlur={handleBlur} />;
};

export default DollarField;
