/* auth types */
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_START = "LOGIN_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const MANUAL_LOGOUT_SUCCESS = "MANUAL_LOGOUT_SUCCESS";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAIL = "REFRESH_TOKEN_FAIL";
export const GROUP_SELECTOR_SHOW = "GROUP_SELECTOR_SHOW";
export const GROUP_SELECTOR_HIDE = "GROUP_SELECTOR_HIDE";
export const LOAD_ALL_SERVICE_GROUPS = "LOAD_ALL_SERVICE_GROUPS";
export const LOAD_ALL_SERVICE_GROUPS_ERROR = "LOAD_ALL_SERVICE_GROUPS_ERROR";
export const CHANGE_SERVICEGROUP_SUCCESS = "CHANGE_SERVICEGROUP_SUCCESS";
export const LOAD_SERVICE_GROUP_SUCCESS = "LOAD_SERVICE_GROUP_SUCCESS";
export const SET_SESSION_TOKEN = "SET_SESSION_TOKEN";
export const BEGIN_MFA = "BEGIN_MFA";
export const MFA_SUCCESS = "MFA_SUCCESS";
export const MFA_FAIL = "MFA_FAIL";
export const CANCEL_MFA = "CANCEL_MFA";
export const SWITCH_TO_INTERNAL_VIEW = "SWITCH_TO_INTERNAL_VIEW";

/* form types */
export const ADD_DROPDOWN_OPTION = "ADD_DROPDOWN_OPTION";
export const GET_FORM_START = "GET_FORM_START";
export const GET_FORM_SUCCESS = "GET_FORM_SUCCESS";
export const GET_FORM_FAIL = "GET_FORM_FAIL";
export const CALL_FORM_ENDPOINT_START = "CALL_FORM_ENDPOINT_START";
export const CALL_FORM_ENDPOINT_SUCCESS = "CALL_FORM_ENDPOINT_SUCCESS";
export const HANDLE_FIELD_CHANGE = "HANDLE_FIELD_CHANGE";
export const HANDLE_FIELD_PUSH = "HANDLE_FIELD_PUSH";
export const HANDLE_INDEXED_FIELD_CHANGE = "HANDLE_INDEXED_FIELD_CHANGE";
export const HANDLE_FIELD_COPY = "HANDLE_FIELD_COPY";
export const RESET_VALIDATION = "RESET_VALIDATION";
export const ADD_VALIDATION_ERROR = "ADD_VALIDATION_ERROR";
export const REMOVE_VALIDATION_ERROR = "REMOVE_VALIDATION_ERROR";
export const SET_DROPDOWN = "SET_DROPDOWN";
export const SUBMIT_FORM_SUCCESS = "SUBMIT_FORM_SUCCESS";
export const SUBMIT_FORM_START = "SUBMIT_FORM_START";
export const SUBMIT_FORM_FAIL = "SUBMIT_FORM_FAIL";
export const CLEAR_DATA = "CLEAR_DATA";
export const SET_STATE_BY_NAME = "SET_STATE_BY_NAME";
export const SET_STATE_NAME_BY_INDEX = "SET_STATE_NAME_BY_INDEX";
export const UPLOAD_ERROR = "UPLOAD_ERROR";
export const UPLOAD_STARTED = "UPLOAD_STARTED";
export const UPLOAD_FINISHED = "UPLOAD_FINISHED";

/* detail types */
export const GET_DETAIL_START = "GET_DETAIL_START";
export const GET_DETAIL_SUCCESS = "GET_DETAIL_SUCCESS";
export const GET_DETAIL_FAIL = "GET_DETAIL_FAIL";
export const CLEAR_DETAIL_DATA = "CLEAR_DETAIL_DATA";
export const TRIGGER_DETAIL_REFRESH = "TRIGGER_DETAIL_REFRESH";
export const DELETE_DETAIL_START = "DELETE_DETAIL_START";
export const DELETE_DETAIL_SUCCESS = "DELETE_DETAIL_SUCCESS";
export const DELETE_DETAIL_FAIL = "DELETE_DETAIL_FAIL";

/* list types */
export const INIT_LIST = "INIT_LIST";
export const GET_LIST_START = "GET_LIST_START";
export const GET_LIST_SUCCESS = "GET_LIST_SUCCESS";
export const GET_LIST_FAIL = "GET_LIST_FAIL";
export const DELETE_START = "DELETE_START";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DELETE_FAIL = "DELETE_FAIL";
export const TRIGGER_GRID_REFRESH = "TRIGGER_GRID_REFRESH";
export const FILTER_ALL_RESET = "FILTER_ALL_RESET";
export const FILTER_SHOW = "FILTER_SHOW";
export const FILTER_HIDE = "FILTER_HIDE";
export const FILTER_VALUE_SET = "FILTER_VALUE_SET";
export const QUICK_SEARCH_SET = "QUICK_SEARCH_SET";
export const UPDATE_LIST_PROP = "UPDATE_LIST_PROP";
export const SET_GRID_FILTERS = "SET_GRID_FILTERS";

/* error types */
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

/* layout types */
export const TOGGLE_NAV = "TOGGLE_NAV";
export const GLOBAL_DIALOG_SHOW = "GLOBAL_DIALOG_SHOW";
export const GLOBAL_DIALOG_HIDE = "GLOBAL_DIALOG_HIDE";
export const END_POINT_START = "END_POINT_START";
export const END_POINT_SUCCESS = "END_POINT_SUCCESS";
export const END_POINT_FAIL = "END_POINT_FAIL";
export const CLEAR_ENDPOINT = "CLEAR_ENDPOINT";
export const SNACK_BAR_ADD = "SNACK_BAR_ADD";
export const SNACK_BAR_PROCESS = "SNACK_BAR_PROCESS";
export const NOTIFICATIONS_FAIL = "NOTIFICATIONS_FAIL";
export const NOTIFICATIONS_HIDE = "NOTIFICATIONS_HIDE";
export const NOTIFICATIONS_SHOW = "NOTIFICATIONS_SHOW";
export const NOTIFICATIONS_SUCCESS = "NOTIFICATIONS_SUCCESS";
export const NOTIFICATION_REMOVED = "NOTIFICATION_REMOVED";
export const NOTIFICATION_REMOVE_ALL = "NOTIFICATION_REMOVE_ALL";
export const NOTIFICATION_VIEWED = "NOTIFICATION_VIEWED";
export const NOTIFICATION_VIEW_ALL = "NOTIFICATION_VIEW_ALL";
export const PRINT_START = "PRINT_START";
export const PRINT_DONE = "PRINT_DONE";
export const RECENT_ACTIVITY_HIDE = "RECENT_ACTIVITY_HIDE";
export const RECENT_ACTIVITY_SHOW = "RECENT_ACTIVITY_SHOW";
export const RECENT_ACTIVITY_SUCCESS = "RECENT_ACTIVITY_SUCCESS";

/* report types */
export const CHANGE_RPT_FILTER = "CHANGE_RPT_FILTER";
export const GET_RPT_FAIL = "GET_RPT_FAIL";
export const GET_RPT_START = "GET_RPT_START";
export const GET_RPT_SUCCESS = "GET_RPT_SUCCESS";
export const GET_RPT_LIST_START = "GET_RPT_LIST_START";
export const GET_RPT_LIST_SUCCESS = "GET_RPT_LIST_SUCCESS";
export const RESET_RPT = "RESET_RPT";
export const RESET_RPT_FILTERS = "RESET_RPT_FILTERS";
export const UPDATE_RPT_HIDDEN_COLUMNS = "UPDATE_RPT_HIDDEN_COLUMNS";
export const SAVE_HIDDEN_COLUMNS_SUCCESS = "SAVE_HIDDEN_COLUMNS_SUCCESS";
export const SAVE_HIDDEN_COLUMNS_FAIL = "SAVE_HIDDEN_COLUMNS_FAIL";

/* drag types */
export const DRAG_EVENT = "DRAG_EVENT";
export const DRAG_REORDER_SHOW = "DRAG_REORDER_SHOW";
export const DRAG_REORDER_HIDE = "DRAG_REORDER_HIDE";
export const DRAG_SET_LIST = "DRAG_SET_LIST";
export const DRAG_SAVE_START = "DRAG_SAVE_START";
export const DRAG_SAVE_SUCCESS = "DRAG_SAVE_SUCCESS";

/* cart types */
export const SHOP_CALL_START = "SHOP_CALL_START";
export const SHOP_CALL_FAIL = "SHOP_CALL_FAIL";
export const SHOP_CALL_SUCCESS = "SHOP_CALL_SUCCESS";
export const SHOP_SET_DATA = "SHOP_SET_DATA";
export const SHOP_SET_CART_DATA = "SHOP_SET_CART_DATA";
export const SHOP_CLEAR_DATA = "SHOP_CLEAR_DATA";
export const SHOP_HANDLE_FIELD_CHANGE = "SHOP_HANDLE_FIELD_CHANGE";
export const SHOP_TOGGLE_POS_CART = "SHOP_TOGGLE_POS_CART";
export const SHOP_POS_COMPLETE_CHECKOUT = "SHOP_POS_COMPLETE_CHECKOUT";
export const SHOP_SET_CART_TOTALS = "SHOP_SET_CART_TOTALS";
export const SHOP_HANDLE_FILTERS_CHANGE = "SHOP_HANDLE_FILTERS_CHANGE";
export const SHOP_CLEAR_FILTERS = "SHOP_CLEAR_FILTERS";
