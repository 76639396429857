import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { NOTIFICATION_TYPES } from "../common/consts";

const notificationSelector = createSelector(
    state => state.auth.serviceGroup?.twilioNotificationMap,
    notifications => {
        const found = Array.isArray(notifications)
            ? notifications.reduce((acc, { notificationTypeID }) => {
                acc[notificationTypeID] = true;
                return acc;
            }, {})
            : notifications || {};

        const surveyEnabled = Boolean(found[NOTIFICATION_TYPES.SURVEY_NOTIFICATION]);
        const billingEnabled = Boolean(found[NOTIFICATION_TYPES.BILLING_NOTIFICATION]);
        const aobEnabled = Boolean(found[NOTIFICATION_TYPES.AOB_NOTIFICATION]);
        const membershipEnabled = Boolean(found[NOTIFICATION_TYPES.MEMBERSHIP_RENEWAL_NOTIFICATION]);

        return {
            SURVEY: surveyEnabled,
            BILL: billingEnabled,
            AOB: aobEnabled,
            MEMBERSHIP: membershipEnabled,
            [NOTIFICATION_TYPES.SURVEY_NOTIFICATION]: surveyEnabled,
            [NOTIFICATION_TYPES.BILLING_NOTIFICATION]: billingEnabled,
            [NOTIFICATION_TYPES.AOB_NOTIFICATION]: aobEnabled,
            [NOTIFICATION_TYPES.MEMBERSHIP_RENEWAL_NOTIFICATION]: membershipEnabled
        };
    }
);

export default function useTwilioNotifications() {
    return useSelector(notificationSelector);
}
