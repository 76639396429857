import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import LoadingIndicatorBar from "./components/LoadingIndicatorBar";
import ErrorBoundary from "./components/ErrorBoundary";
import GlobalDialog from "./components/GlobalDialog";
import GlobalSnackbar from "./components/GlobalSnackbar";
import BrowserCheck from "./components/BrowserCheck";
import store, { history } from "./store";
import routesPublic from "./common/routesPublic";
import theme from "./theme";
import * as serviceWorker from "./serviceWorker";
import GlobalError from "./components/Error";
import RedirectTrailingSlash from "./components/RedirectTrailingSlash";
import { datadogLogs } from "@datadog/browser-logs";
import { DATA_DOG_CLIENT_TOKEN, DATA_DOG_SITE } from "./common/consts";
import { version } from "../package.json";

if (process.env.NODE_ENV === "production") {
    datadogLogs.init({
        clientToken: DATA_DOG_CLIENT_TOKEN,
        site: DATA_DOG_SITE,
        service: "eServicesClient",
        forwardErrorsToLogs: true,
        version
    });
}

const App = React.lazy(() => import("./App"));

ReactDOM.render(
    <BrowserCheck>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <ConnectedRouter history={history}>
                    <ErrorBoundary>
                        <Suspense fallback={<LoadingIndicatorBar />}>
                            <CssBaseline />
                            <RedirectTrailingSlash />
                            <Switch>
                                {routesPublic.map(rte => {
                                    const RouteComponent = rte.component;
                                    return <Route exact key={rte.path} path={rte.path} render={props => <RouteComponent {...props} {...rte.props} />} />;
                                })}
                                <Route>
                                    <App />
                                </Route>
                            </Switch>
                        </Suspense>
                        <GlobalError />
                        <GlobalDialog />
                        <GlobalSnackbar />
                    </ErrorBoundary>
                </ConnectedRouter>
            </ThemeProvider>
        </Provider>
    </BrowserCheck>,
    document.getElementById("root")
);

serviceWorker.unregister();
