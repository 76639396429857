import getStorageItem from "../helpers/getStorageItem";
import {
    AUTH_ERROR,
    BEGIN_MFA,
    CANCEL_MFA,
    CHANGE_SERVICEGROUP_SUCCESS,
    GROUP_SELECTOR_HIDE,
    GROUP_SELECTOR_SHOW,
    LOAD_ALL_SERVICE_GROUPS,
    LOAD_SERVICE_GROUP_SUCCESS,
    LOGIN_FAIL,
    LOGIN_START,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    MANUAL_LOGOUT_SUCCESS,
    MFA_FAIL,
    MFA_SUCCESS,
    REFRESH_TOKEN,
    REFRESH_TOKEN_FAIL,
    REFRESH_TOKEN_SUCCESS,
    SET_SESSION_TOKEN,
    SWITCH_TO_INTERNAL_VIEW,
    USER_LOADED
} from "../actions/types";

const serviceGroupJSON = getStorageItem("serviceGroup");
const initialState = {
    accessToken: getStorageItem("accessToken"),
    refreshToken: getStorageItem("refreshToken"),
    sessionToken: getStorageItem("sessionToken"),
    posToken: getStorageItem("posToken"),
    mfaToken: getStorageItem("mfaToken"),
    fetching: false,
    refreshing: false,
    refreshTokenPromise: null,
    serviceGroup: serviceGroupJSON ? JSON.parse(serviceGroupJSON) : {},
    sgSelectorOpen: false,
    allGroups: [],
    isAuthenticated: null,
    user: null,
    showInternalView: getStorageItem("showInternalView")
};

const authReducer = function(state = initialState, action) {
    switch (action.type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload
            };
        case LOGIN_SUCCESS:
        case MFA_SUCCESS:
        case CHANGE_SERVICEGROUP_SUCCESS:
            return {
                ...state,
                ...action.payload,
                fetching: false,
                isAuthenticated: true,
                showInternalView: false
            };
        case SET_SESSION_TOKEN:
            return {
                ...state,
                sessionToken: action.payload
            };
        case LOGIN_START:
            return {
                ...state,
                fetching: true
            };
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT_SUCCESS:
        case REFRESH_TOKEN_FAIL:
            return {
                ...state,
                accessToken: null,
                refreshToken: null,
                posToken: null,
                refreshing: false,
                fetching: false,
                isAuthenticated: false,
                user: null,
                serviceGroup: {}
            };
        case MANUAL_LOGOUT_SUCCESS: // clear mfa token on manual logout
            return {
                ...state,
                accessToken: null,
                refreshToken: null,
                posToken: null,
                mfaToken: null,
                refreshing: false,
                isAuthenticated: false,
                user: null,
                serviceGroup: {}
            };
        case MFA_FAIL:
            return {
                ...state,
                accessToken: null,
                refreshToken: null,
                posToken: null,
                refreshing: false,
                isAuthenticated: false,
                user: null,
                serviceGroup: {},
                awaitingMFA: true
            };
        case REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                ...action.payload,
                refreshing: false,
                refreshTokenPromise: null
            };
        case REFRESH_TOKEN:
            return {
                ...state,
                refreshing: true,
                refreshTokenPromise: action.payload
            };
        case LOAD_SERVICE_GROUP_SUCCESS: {
            return {
                ...state,
                serviceGroup: action.payload,
                showInternalView: false
            };
        }
        case GROUP_SELECTOR_SHOW:
            return {
                ...state,
                sgSelectorOpen: true
            };
        case GROUP_SELECTOR_HIDE:
            return {
                ...state,
                sgSelectorOpen: false
            };
        case LOAD_ALL_SERVICE_GROUPS:
            return {
                ...state,
                allGroups: action.payload
            };
        case BEGIN_MFA:
            return {
                ...state,
                accessToken: null,
                refreshToken: null,
                posToken: null,
                refreshing: false,
                isAuthenticated: false,
                user: null,
                serviceGroup: {},
                ...action.payload
            };
        case CANCEL_MFA:
            return {
                ...state,
                awaitingMFA: false
            };
        case SWITCH_TO_INTERNAL_VIEW:
            return {
                ...state,
                serviceGroup: {},
                showInternalView: true
            };
        default:
            return state;
    }
};

export default authReducer;
